export type Color =
  | "light"
  | "dark"
  | "darker"
  | "accent"
  | "primary"
  | "secondary"

export function getColor(color: Color) {
  switch (color) {
    case "light":
      return "text-base-color"
    case "dark":
      return "text-body"
    case "darker":
      return "text-header"
    default:
      throw new Error("Invalid color provided")
  }
}
