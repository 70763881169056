import tw, { styled } from "twin.macro"
import loadable from "@loadable/component"
const ParralaxBackgroundSection = loadable(
  () => import("../styles/parralax-background-section")
)

export const Banner = styled(ParralaxBackgroundSection)`
  ${tw`my-0`}
  @media (min-width: 788px) {
    margin-top: -400px;
    height: 600px;
  }
`
