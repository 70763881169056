import tw from "twin.macro"

export const H1 = tw.h1`
  text-header
  text-4xl
  lg:text-6xl
  font-sans
  leading-none
  font-semibold
`
