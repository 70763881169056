import tw, { styled } from "twin.macro"
import { Link } from "gatsby"

export const ButtonLink = styled(Link)`
  ${tw`
    px-6 
    py-2
    my-4
    w-max
    text-sm
    self-center
    text-base-color 
    uppercase
    bg-transparent
    border-2
    border-white
    hover:border-primary
    hover:bg-primary
    font-serif
  `}

  /* hover:text-black */
  /* hover:bg-white
    hover:text-black */
  &:hover {
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    filter: brightness(85%);
  }
`
