import React from "react"
import tw, { styled } from "twin.macro"
import { H3, P } from "../page-elements"
import { Link } from "gatsby"
import Image from "../image"
import CompanyInfo from "../../content/business-details.json"
import { Content as ServicesContent } from "../../pages/services"

const LogoLight = "glacier_logo.png"

export const Footer: React.FC = () => {
  return (
    <Container>
      <Row>
        <Col>
          <Image
            src={LogoLight}
            className="w-1/3 md:w-1/4 my-8"
            alt="Logo"
            width="309"
            height="68"
          />
          <ListItem color="light">{CompanyInfo.address.street}</ListItem>
          <ListItem color="light">
            {CompanyInfo.address.city}, {CompanyInfo.address.state}{" "}
            {CompanyInfo.address.zip}
          </ListItem>
          <ListItem color="light">{CompanyInfo.email}</ListItem>
          <ListItem color="light">{CompanyInfo.phone}</ListItem>
        </Col>
        <Col>
          <H3 className="text-base-color mb-6 pb-6 border-b-2 border-accent w-1/2">
            Quick Links
          </H3>
          <div className="flex flex-col">
            <QuickLink to="/">Home</QuickLink>
            <QuickLink to="/about">About</QuickLink>
            <QuickLink to="/services">Services</QuickLink>
            <QuickLink to="/portfolio">Portfolio</QuickLink>
            <QuickLink to="/contact">Contact</QuickLink>
          </div>
        </Col>
        <Col>
          <H3 className="text-base-color mb-6 pb-6 border-b-2 border-accent w-1/2">
            Services
          </H3>
          <div className="flex flex-col">
            {ServicesContent.services.map(({ title }) => (
              <ListItem key={title} color="light">
                {title}
              </ListItem>
            ))}
          </div>
        </Col>
      </Row>
      <Row className="">
        <P className="text-xs text-center">
          Glacier Painting - Glacier Painting LLC - Glacier Painting Dane County
          - Dane County Painting Services - Madison Painting Services - Painting
          in Dane County - Commercial Painting in Madison WI - Residential
          Painting in Madison WI - Exterior Painting in Dane County - Painting
          Services Near Me - Glacier Painting in Madison WI
        </P>
      </Row>
      <div className="bg-charcoal-dark w-full p-2">
        <Bottom>
          Copyright © {new Date().getFullYear()} | Glacier Painting &
          Restoration | All Rights Reserved | Powered By{" "}
          <Link
            className="hover:text-accent"
            to="https://outofminddesign.com"
            target="_blank"
            rel="noreferrer"
          >
            Out Of Mind Web Design
          </Link>
        </Bottom>
      </div>
    </Container>
  )
}

const Container = tw.footer`
  relative
  overflow-hidden
  bg-aqua
`

const Col = tw.div`
  w-full
  mt-4
  md:mt-0
  md:py-8
  md:flex-1
`

const Row = styled.div`
  ${tw`
  py-8
  px-page-gutter
  flex
  flex-wrap
  m-auto
  max-w-screen-xl
  `}
`

const QuickLink = styled(Link)`
  ${tw`text-base-color text-base my-1`}
`

const ListItem = styled(P)`
  ${tw`text-base-color text-base my-1`}
`

const Bottom = styled(P)`
  ${tw`mt-0 text-base-color text-xs text-center  items-center content-center mt-0`}
`
