import React from "react"
import { Link } from "gatsby"
import tw from "twin.macro"
import Image from "../image"
import { LOGO, menuItems } from "../../constants"
import { MobileBrand } from "../styles/mobile-brand"
import styled, { keyframes } from "styled-components"

interface MobileHeaderDrawerProps {
  isOpen: boolean
  onToggleMenu(): void
}

export const HeaderMobileDrawer: React.FC<MobileHeaderDrawerProps> = ({
  isOpen,
  onToggleMenu,
}) => {
  return (
    <>
      {isOpen && <Overlay onClick={onToggleMenu} />}
      <MobileMenuDrawer isOpen={isOpen}>
        <BrandWrapper>
          <MobileBrand to="/">
            <Image src={LOGO.DARK} alt="Logo" className="w-16" />
            <p className={`pl-2`}>Glacier Painting</p>
          </MobileBrand>
        </BrandWrapper>
        <MobileMenu role="navigation" aria-label="Mobile Menu">
          {menuItems.map(({ url, text }, i) => (
            <AnimatedLink
              className={isOpen ? "open" : ""}
              key={text}
              to={url}
              activeClassName="active"
              onClick={onToggleMenu}
              index={i}
            >
              {text}
            </AnimatedLink>
          ))}
        </MobileMenu>
      </MobileMenuDrawer>
    </>
  )
}

const BrandWrapper = tw.div`
flex justify-between px-4 mb-4
`

const MobileMenuDrawer = styled.div`
  ${tw`
    fixed
    top-0
    right-0
    bottom-0
    overflow-hidden
    overflow-y-scroll	
    bg-white
    rounded-tl-md
    py-4
    w-4/5
  `}
  z-index:9999;
  max-width: 400px;
  transition: transform 0.2s ease-in-out;
  transform: ${({ isOpen }: { isOpen: boolean }) =>
    isOpen ? "translateX(0)" : "translateX(100%)"};
`

const MobileMenu = tw.div`
  w-full
  flex
  flex-col
`

const StyledLink = styled(Link)`
  ${tw`
    text-base
    text-base-color
    mx-4
    p-4
    md:text-lg
    md:font-bold
  `}
`

const MobileLink = styled(StyledLink)`
  ${tw`
    text-header
    rounded-md
    p-2
    
  `}
  &.active {
    background: var(--color-base-secondary);
    color: var(--color-primary);
  }
`

const slideIn = keyframes`
  0% {
    opacity: 0;
		transform: translateX(100px);
  }

  100% {
		transform: translateX(0);
    opacity: 1;
  }
`

const Overlay = styled.div`
  ${tw`
    fixed
    top-0
    right-0
    bottom-0
    w-full
  `}
  z-index: 9999;
  background: rgba(0, 0, 0, 0.3);
`

const AnimatedLink = styled(MobileLink)`
  transform: translateX(100px);
  opacity: 0;
  &.open {
    animation-name: ${slideIn};
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
    animation-timing-function: linear;
    animation-delay: ${({ index }: { index: number }) => `.${index}`}s;
  }
`
