import React from "react"
import HamburgerMenu from "./hamburger-menu"
import Image from "../image"
import { LOGO } from "../../constants"
import { MobileBrand } from "../styles/mobile-brand"

interface MobileHeaderProps {
  onToggleMenu(): void
}

export const HeaderMobile: React.FC<MobileHeaderProps> = ({ onToggleMenu }) => {
  return (
    <>
      <MobileBrand to="/">
        <Image
          src={LOGO.DARK}
          alt="Logo Dark"
          className="w-16"
          height="35"
          width="160"
        />
      </MobileBrand>
      <p className={``}>Glacier Painting</p>
      <HamburgerMenu onClick={onToggleMenu} />
    </>
  )
}
