import React from "react"
import loadable from "@loadable/component"
import styled from "styled-components"
import tw from "twin.macro"
import { P, H1, H2, H4, HeaderDivider } from "../components/page-elements"
import Image from "../components/image"
import { FeatureList } from "../components/feature-list"
import { Column } from "../components/styles/column"
import { Banner } from "../components/shared/banner"
import { CalloutSection } from "../components/shared/callout-section"
import CalloutContent from "../content/callout-section-content.json"
import SEO from "../components/seo"
import Icon from "../components/icon"
const Section = loadable(() => import("../components/shared/section"))
const backgroundImage = "glacier-background.jpg"

export const Content = {
  meta: {
    title: "Services",
    description: "",
  },
  header: "Our Services",
  details:
    "Glacier offers commercial and home painting services to all our clients in the Madison metropolitan area. Whether you're looking to up your curb appeal or freshen up the look of the interior of your home with our painting services, we'll deliver the professional service, quality and value you desire.",
  services: [
    {
      title: "Residential Painting",
      body:
        "Our paint contractors will trust your home with the utmost respect. We strive to complete the painting project correctly, promptly, and with as little interruption of your daily life as possible. We will review everything with you before we start painting the interior of your home, so we understand exactly what you want, preparing and protecting all surfaces carefully.",
      icon: "residential.png",
      alt: "Hand holding a mobile phone",
    },
    {
      title: "Commercial Painting",
      body:
        "No commercial painting job is too big or too small for us. Our collective knowledge, expertise, and industry resources in commercial painting can provide a solution that meets your timeline and budget. We can increase the visual appeal and the overall value of your commercial property, without interrupting the flow of your business. We offer very flexible hours so we can perform our commercial painting job at the most convenient time for you.",
      icon: "commercial.png",
      alt: "Mobile Phone and Tablet",
    },
    {
      title: "Interior Painting",
      body:
        "Our interior painters have years of experience completing residential and commercial painting projects promptly and professionally. We understand the importance of providing high quality painting services without disrupting your daily life, whether it’s in the workspace or at home. Glacier offers organized and timely services with flexible hours to fit the needs of you or your business.",
      icon: "interior.png",
      alt: "Chemistry flask and female scientist",
    },
    {
      title: "Exterior Painting",
      body:
        "A well executed exterior painting job can dramatically increase the curb appeal, and thus the value of your home. The exterior of your home is at the highest risk of having areas that need to be repainted, as outside elements can make a poor paint job peel within a year or so, making it important to trust in a professional paint contractor. Glacier has the knowledge and materials to provide you with an excellent exterior painting job that will last. We will review the scope of your exterior painting project with you, to ensure that we fully understand your goals with the painting of the exterior of your home.",
      icon: "exterior.png",
      alt: "Hand holding a mobile phone",
    },
    {
      title: "Wood Restoration",
      body:
        "Wood restoration and refinishing should be a task you trust to the professionals. Glacier Painting is Madisons leader in the field of wood preservation. Known for our professional installation services and restoration of cedar shake roofs, we also clean and maintain decks, fences, gazebos, log homes and wood siding. If it's wood, we are the experts to call. Glacier Painting can provide you with a customized wood restoration service of the highest quality.",
      icon: "woodrestore.png",
      alt: "Mobile Phone and Tablet",
    },
    {
      title: "Wood Varnishing",
      body:
        "Staining and Varnishing is an excellent way to produce a high quality finish for your interior wood trim, doors, cabinets, shelving or other wood projects. Staining adds colors and tints to the wood and varnishing seals the wood from moisture damage. Wood finishing isn't complicated, but it does require patience and attention to detail. Glacier Painting trained & experienced professionals have over a decades experience providing interior staining services and interior varnishing services. Your wood will look fabulous and last a very long time.",
      icon: "woodvarnish.png",
      alt: "Chemistry flask and female scientist",
    },
    {
      title: "Deck Staining",
      body:
        "Installing a deck, wooden fence or siding can be a substantial investment, and many times people don't realize that they can easily deteriorate from the elements, and they'll end up having to replace them. The products we use to restore your deck or other exterior wood pieces can protect it from the elements (including UV damage, mold, algae and fungus), and nourish it. Restore your deck, fence, gazebo, siding, and more with the professional service of Madison's wood restoration experts.",
      icon: "deckstain.png",
      alt: "Chemistry flask and female scientist",
    },
    {
      title: "Deck Cleaning",
      body:
        "Your deck or fence can add years of beauty to your home if properly maintained. Keeping them in top condition ensures their structural integrity as well. Not only will your deck or fence remain beautiful with our cleaning and restoration process, but safe and functional as well.",
      icon: "deckcleaning.png",
      alt: "Chemistry flask and female scientist",
    },
    {
      title: "Drywall Repair",
      body:
        "Glacier Painting’s drywall experts are skilled and dedicated to a job well done. With a close attention to detail, we can restore the walls and ceilings of your home or business. Glacier always works with your schedule and budget to alleviate any stress.",
      icon: "drywall.png",
      alt: "Chemistry flask and female scientist",
    },
    {
      title: "Pressure Washing",
      body:
        "Elevate your building’s curb appeal with Glacier’s pressure washing services. We’ll deliver a clean look that reflects the interior of your residential and commercial spaces. Our professionals have the tools and expertise necessary to transform the exterior of your house or office.",
      icon: "pressurewash.png",
      alt: "Chemistry flask and female scientist",
    },
    {
      title: "Carpentry Repairs",
      body:
        "Up-to-date carpentry improves the health and structure of a building. Glacier’s carpenters can extend the life of your building by repairing and replacing any structural wood. Our experts are here to help with your commercial or residential carpentry needs.",
      icon: "carpentry.png",
      alt: "Chemistry flask and female scientist",
    },
    {
      title: "EIFS Repair & Painting",
      body:
        "Over time, the exterior of your building is exposed to all kinds of weather conditions and your Exterior Insulation and Finish Systems (EIFS) can wear down. Glacier Painting offers repairs on EIFS so that your home or business remains protected from weather conditions while providing insulation.",
      icon: "eifs.png",
      alt: "Chemistry flask and female scientist",
    },
    {
      title: "Stucco Repair & Painting",
      body:
        "Before sprucing up your commercial and residential building’s exterior with a paint job, it’s important to make necessary repairs. Glacier Painting will repair your stucco walls as well as paint them, which gives your building exterior even more protection from damage.",
      icon: "stucco.png",
      alt: "Chemistry flask and female scientist",
    },
  ],
  features: {
    title: "Why Choose Us",
    text:
      "Glacier Painting offers Residential Painting Services & Commercial Painting Services to Madison, Dane County, and all of Southern Wisconsin. We are fully licensed and insured & Encourage you to take advantage of our affordable Madison Interior Painting Services, Madison Deck & Wood Refinishing Services, & other residential & commercial painting contractor services.",
    src: "gallery-uhaul-other.jpg",
    alt: "Cutting Tree",
    items: [
      {
        src: "collaboration.png",
        alt: "Hand Shake Icon",
        title: "Community Trust",
        text:
          "Glacier Painting's Residential and Commercial Services have created loyal customers throughout the Madison metropolitan area.",
      },
      {
        src: "clipboard.png",
        alt: "Clipboard Icon",
        title: "Professional Service",
        text:
          "Our personnel and staff have years of experience in the Residential & Commercial Painting Industry as well as the Wood Refinishing & Restoration Industry. Our on-site foremen have years of experience in painting, construction, and running a work site efficiently. We also employ qualified and reliable painters and workmen who are professional and highly skilled.",
      },
      {
        src: "process.png",
        alt: "Cog Icon",
        title: "Simple Process",
        text:
          "We employ project managers that will guide you Step-By-Step through your home improvement project or commercial project. They are knowledgeable and friendly and will always be there to answer any concerns or questions that may come up during a job.",
      },
    ],
  },
}

const ServicesPage = () => {
  return (
    <>
      <SEO title={Content.meta.title} description={Content.meta.description} />
      <Banner src={backgroundImage}>
        <Row>
          <InnerH1>{Content.header}</InnerH1>
        </Row>
      </Banner>

      {/* Summary */}
      <Row className="mt-8">
        <Section size="xl">
          <ContentBody>
            <H2 color="darker" className="text-center">
              Glacier Painting Services
            </H2>
            <HeaderDivider color="primary" className="text-center mx-auto" />
            <ContentText
              color="darker"
              className="text-center text-lg max-w-screen-lg mx-auto"
            >
              {Content.details}
            </ContentText>
          </ContentBody>
        </Section>
      </Row>

      {/* Services */}
      <Row className="mb-12">
        <div className="flex flex-wrap md:flex-row justify-center items-start">
          {Content.services.map(({ title, body, icon, alt }) => {
            return (
              <Block key={title}>
                <Icon
                  src={icon}
                  alt={alt}
                  className="text-center mx-auto my-4 w-16"
                />
                {/* <Bookmark className="text-center mx-auto my-4" /> */}
                <BlockHeader>{title}</BlockHeader>
                <P color="darker" className="text-center leading-loose">
                  {body}
                </P>
              </Block>
            )
          })}
        </div>
      </Row>

      <CalloutSection
        title={CalloutContent.title}
        text={CalloutContent.text}
        buttonText={CalloutContent.buttonText}
        buttonUrl={CalloutContent.buttonUrl}
      />

      <div className="my-8 mb-12 flex-col">
        <ContentBody className="my-8 py-8">
          <H2 color="darker" className="text-center">
            {Content.features.title}
          </H2>

          <HeaderDivider color="primary" className="text-center mx-auto" />

          <ContentText
            color="darker"
            className="text-center text-lg max-w-screen-lg mx-auto"
          >
            {Content.features.text}
          </ContentText>
        </ContentBody>

        <Row>
          <Column className="my-8 md:my-0 md:mr-10">
            <FeatureList features={Content.features.items} />
          </Column>
          <Column>
            <Image src={Content.features.src} alt={Content.features.alt} />
          </Column>
        </Row>
      </div>
    </>
  )
}

const Row = tw.div`
  flex
  flex-col
  justify-between
  md:flex-row
  md:mx-auto
  px-page-gutter
  md:px-0
  w-full
  max-w-screen-xl
`

const ContentBody = tw.div`
  w-full
  flex
  flex-col
`

const ContentText = styled(P)`
  ${tw`text-body my-4`}
`

const InnerH1 = styled(H1)`
  ${tw`text-center text-base-color my-8 md:my-16 xl:my-20 mx-auto`}
`

const Block = tw.div`
  flex 
  flex-col 
  justify-center
  text-left
  my-4
  md:my-6
  w-full
  md:w-2/5
  mx-2
  md:mx-4
  md:px-2
`

const BlockHeader = styled(H4)`
  ${tw`
    text-center
    text-2xl
    font-bold
    my-4
  `}
`

export default ServicesPage
