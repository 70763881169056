import React from "react"
import { Link } from "gatsby"
import tw, { styled } from "twin.macro"
import Image from "../image"
import { menuItems, LOGO } from "../../constants"

const DesktopMenu = tw.div`
  hidden
  md:flex
  md:block
  md:flex-auto
  md:text-right
`
const DesktopBrand = styled(Link)(
  tw`
  md:flex
  items-center
  content-center
  no-underline
  hidden
`
)
const DeskTopLink = styled(Link)`
  ${tw`
    relative
    text-base 
    mx-4 
    py-2
  `}

  ${({ isDark }: { isDark?: boolean }) =>
    isDark ? tw`text-header` : tw`text-base-color`}

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    transition: 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    background: ${({ isDark }: { isDark?: boolean }) =>
      isDark ? "var(--color-header-text)" : "var(--color-accent)"};
    /* isDark ? "var(--color-header-text)" : "white"}; */

    transform: scale3d(0, 5, 1);
    transform-origin: 0% 50%;
  }
  &:hover:after {
    transform: scale3d(1, 1, 1);
  }
  &.active :after {
    transform: scale3d(1, 1, 1);
  }
`

export const HeaderDesktop = ({ isDark }: { isDark?: boolean }) => {
  return (
    <>
      <DesktopBrand to="/">
        <Image
          src={isDark ? LOGO.DARK : LOGO.LIGHT}
          alt="Logo"
          className="w-16"
        />
        <p className={`pl-2 ${isDark ? "" : "text-base-color"}`}>
          Glacier Painting
        </p>
      </DesktopBrand>

      <DesktopMenu role="navigation" aria-label="Desktop Menu">
        {menuItems.map(({ url, text }) => (
          <DeskTopLink
            key={text}
            to={url}
            isDark={isDark}
            activeClassName="active"
          >
            {text}
          </DeskTopLink>
        ))}
      </DesktopMenu>
    </>
  )
}
