import React from "react"
import { Color } from "../../utils/getColor"

interface HeaderDividerProps {
  color?: Color
  className?: string
}

const baseClasses = "h-0 w-16 border-2 mb-6"

// I use the full class names here so purging works.
export function getBorderColor(color: Color) {
  switch (color) {
    case "accent":
      return "border-accent"
    case "primary":
      return "border-primary"
    case "secondary":
      return "border-secondary"
    default:
      throw new Error("Invalid color provided")
  }
}

export const HeaderDivider: React.FC<HeaderDividerProps> = ({
  color = "accent",
  className,
}) => {
  return (
    <span className={`${baseClasses} ${className} ${getBorderColor(color)}`} />
  )
}
