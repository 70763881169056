import React from "react"
import { getColor, Color } from "../../utils/getColor"

interface H2Props {
  color: Color
  className?: string
}

const baseClasses =
  "text-3xl md:text-4xl mb-4 md:mb-4 font-sans font-semibold leading-none"

export const H2: React.FC<H2Props> = ({
  children,
  className,
  color = "light",
}) => {
  return (
    <h2 className={` ${baseClasses} ${className} ${getColor(color)}`}>
      {children}
    </h2>
  )
}
