import React from "react"
import tw, { styled } from "twin.macro"
import Icon from "./icon"
import Image from "./image"
import { H5, P } from "./page-elements"

const Feature = tw.div`
    px-4
    my-3
    md:px-0
    flex
`

const ImageColumn = tw.div`
  mx-auto
  flex-none
  w-10
  md:w-8
  mr-4
`

const Title = styled(H5)`
  ${tw`text-left font-bold `}
`

const TextContent = tw.div`
  flex-auto
`

interface FeatureListProps {
  features: Array<{ src: string; alt: string; title: string; text: string }>
}

export const FeatureList: React.FC<FeatureListProps> = ({ features }) => {
  return (
    <>
      {features.map(({ src, alt, title, text }, i) => (
        <Feature key={i}>
          <ImageColumn>
            <Image src={src} alt={alt} height="40" width="40" />
          </ImageColumn>
          <TextContent>
            <Title color="darker">{title}</Title>
            <P color="dark">{text}</P>
          </TextContent>
        </Feature>
      ))}
    </>
  )
}
