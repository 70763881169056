import { Link } from "gatsby"
import tw, { styled } from "twin.macro"

export const MobileBrand = styled(Link)`
  ${tw`
    flex
    md:hidden
    items-center
    content-center
    no-underline
`}
`
