import tw, { styled } from "twin.macro"

export const Button = styled.button`
  ${tw`
    bg-primary
    inline-block
    rounded 
    px-4 
    my-4
    md:px-5 
    xl:px-4 
    py-4 
    md:py-4 
    xl:py-4 
    hover:bg-primary
    md:text-lg 
    xl:text-base-color 
    text-base-color 
    leading-tight 
    shadow-md
    uppercase
    transition
    ease-in-out
    duration-200
    font-semibold
  `}

  &:hover {
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    filter: brightness(85%);
  }
`
