import React from "react"
import Header from "./common/header"
import { Footer } from "./common/footer"
import "../theme/globals.css"
import ViewportProvider from "../context/ViewportContext"

interface LayoutProps {
  path: string
}

const Layout: React.FC<LayoutProps> = ({ path, children }) => {
  const isNotHomePage = path !== "/"

  return (
    <ViewportProvider>
      <Header shouldBeDark={isNotHomePage} />
      <main>{children}</main>
      <Footer />
    </ViewportProvider>
  )
}

export default Layout
