import React, { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

interface ImageProps {
  src: string
  alt: string
  className?: string
}

const Image: React.FC<ImageProps> = ({ src, ...rest }) => {
  let { desktop, medium, small } = useStaticQuery(
    graphql`
      query {
        desktop: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          nodes {
            ...DeskTopImageForeground
          }
        }
        medium: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          nodes {
            ...MediumImageForeground
          }
        }
        small: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          nodes {
            ...SmallImageForeground
          }
        }
      }
    `
  )

  desktop = useMemo(
    () => desktop.nodes.find((node: any) => src === node.relativePath),
    [desktop, src]
  )

  medium = useMemo(
    () => medium.nodes.find((node: any) => src === node.relativePath),
    [medium, src]
  )

  small = useMemo(
    () => small.nodes.find((node: any) => src === node.relativePath),
    [small, src]
  )

  // if image wasn't found
  if (!small) {
    return null
  }

  // if image is an svg
  if (small.extension === "svg") {
    return <img src={small.publicURL} {...rest} loading="lazy" />
  }

  const sources = [
    small.childImageSharp.fluid,
    {
      ...medium.childImageSharp.fluid,
      media: `(min-width: 491px)`,
    },
    {
      ...desktop.childImageSharp.fluid,
      media: `(min-width: 1401px)`,
    },
  ]

  return <Img fluid={sources} {...rest} />
}

export default Image
