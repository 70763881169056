import React from "react"
import { viewportContext } from "../context/ViewportContext"

const useViewport = () => {
  const { width, height } = React.useContext<
    Partial<{ width: number; height: number }>
  >(viewportContext)
  return { width, height }
}

export default useViewport
