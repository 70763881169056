import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import useViewport from "../../hooks/useViewport"
import { BREAKPOINTS } from "../../constants"
import useDocumentScrollThrottled from "../../hooks/useDocumentScrollThrottled"
import { HeaderDesktop } from "./header-desktop"
import { HeaderMobile } from "./header-mobile"
import { HeaderMobileDrawer } from "./header-mobile-drawer"

const MINIMUM_SCROLL = 160

const StyledHeader = styled.header`
  ${tw`
    flex
    justify-between
    w-full
    mb-2
    bg-transparent
    md:absolute
    md:top-0
    md:mx-auto
    md:self-center
    md:z-10
  `}

  @media (min-width: 768px) {
    z-index: 9999;
  }

  ${({ shouldBeDark }: { shouldBeDark: boolean }) => {
    return shouldBeDark ? tw`md:text-black` : tw`md:bg-transparent `
  }}
`

interface HeaderProps {
  headerHeight: number
  shouldShowHeader: boolean
}

const StaticHeader = styled(StyledHeader)`
  ${tw`
    h-full
    md:h-20
    md:my-10
  `}
`

const StickyHeader = styled(StyledHeader)`
  ${tw`
    fixed
    bg-white
    text-black
    overflow-hidden
    h-20
    top-0
    shadow
    z-10
  `};
  z-index: 9999;
  transition: 0.2s ease-in-out;
  max-height: ${(props: HeaderProps) => props.headerHeight - 10 + "px"};
  transform: ${(props: HeaderProps) =>
    props.shouldShowHeader ? "translateY(0%)" : "translateY(-200%)"};
`

const Nav = tw.nav`
  flex
  justify-between
  w-full
  m-auto
  items-center
  max-w-screen-xl	
  px-page-gutter  
`

interface CallBackProp {
  previousScrollTop: number
  currentScrollTop: number
}

interface HeaderProps {
  shouldBeDark: boolean
}

const Header: React.FC<HeaderProps> = ({ shouldBeDark }) => {
  const headerRef = useRef<any>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [headerHeight, setHeaderHeight] = useState(0)
  const [shouldShowHeader, setShouldShowHeader] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  const { width } = useViewport()

  useEffect(() => {
    setIsMobile(width! < BREAKPOINTS.MD)
  }, [width])

  useEffect(() => {
    setHeaderHeight(headerRef?.current?.clientHeight)
  }, [])

  useDocumentScrollThrottled((callbackData: CallBackProp) => {
    const { currentScrollTop } = callbackData
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL
    setShouldShowHeader(isMinimumScrolled)
  })

  const handleToggleMenu = () => {
    setIsOpen(current => !current)
  }

  return (
    <>
      <StaticHeader ref={headerRef} shouldBeDark={false}>
        <Nav>
          {isMobile ? (
            <HeaderMobile onToggleMenu={handleToggleMenu} />
          ) : (
            <HeaderDesktop isDark={false} />
          )}
        </Nav>
      </StaticHeader>
      {!isMobile && (
        <StickyHeader
          headerHeight={headerHeight}
          shouldShowHeader={shouldShowHeader}
        >
          <Nav>
            {isMobile ? (
              <HeaderMobile onToggleMenu={handleToggleMenu} />
            ) : (
              <HeaderDesktop isDark={shouldShowHeader} />
            )}
          </Nav>
        </StickyHeader>
      )}
      {isMobile && (
        <HeaderMobileDrawer onToggleMenu={handleToggleMenu} isOpen={isOpen} />
      )}
    </>
  )
}

export default Header
