import React from "react"
import { useStaticQuery, graphql } from "gatsby"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

interface ImageProps {
  src: string
  alt: string
  className?: string
}

const Icon: React.FC<ImageProps> = ({ src, ...rest }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "icons" } }) {
          nodes {
            ...Icon
          }
        }
      }
    `
  )

  const match = data.allFile.nodes.filter(
    ({ relativePath }: { relativePath: string }) => relativePath === src
  )[0]

  // if image wasn't found
  if (!match) {
    return null
  }

  return <img src={match.publicURL} {...rest} loading="lazy" />
}

export default Icon
