import React from "react"
import tw, { styled } from "twin.macro"
import { H2, P, ButtonLink } from "../page-elements"

const ContactSection = tw.div`
  bg-primary
  text-white
  w-full
  flex
  py-8
`

const Content = tw.div`
  flex
  flex-col
  justify-between
  items-center
  md:flex-row
  md:mx-auto
  px-page-gutter
  md:px-0
  w-full
  max-w-screen-xl
`

const TextContent = tw.div`
  text-white
  md:w-1/2
  w-full
  my-4
  md:my-0
`

const StyledH2 = styled(H2)`
  ${tw`
    text-base-color
  `}
`

const StyledP = styled(P)`
  ${tw`
    text-base-color
  `}
`

interface CalloutSectionProps {
  title: string
  text: string
  buttonText: string
  buttonUrl: string
}

export const CalloutSection: React.FC<CalloutSectionProps> = ({
  title,
  text,
  buttonText,
  buttonUrl,
}) => {
  return (
    <ContactSection>
      <Content>
        <TextContent>
          <StyledH2>{title}</StyledH2>
          <StyledP>{text}</StyledP>
        </TextContent>
        <TextContent className="text-center">
          <ButtonLink to={buttonUrl}>{buttonText}</ButtonLink>
        </TextContent>
      </Content>
    </ContactSection>
  )
}
