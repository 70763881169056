import React from "react"
import { getColor, Color } from "../../utils/getColor"

interface PProps {
  color: Color
  className?: string
}

const baseClasses = "font-sans-secondary"

export const P: React.FC<PProps> = ({
  children,
  className,
  color = "light",
}) => {
  return (
    <p className={`${baseClasses} ${className} ${getColor(color)}`}>
      {children}
    </p>
  )
}
